import $ from 'jquery';
import 'what-input';
import retina from 'retinajs';
import * as Sliders from './components/Sliders';
import LazyLoader from './components/LazyLoad2';

import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { faFacebook, faTwitter, faYoutube, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faSquare, faSearch, faCircleNotch, faFileDownload, faArrowRight, faArrowLeft, faEnvelope as faEnvelopeSolid } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
library.add(faFacebook, faTwitter, faYoutube, faInstagram, faSquare, faSearch, faCircleNotch, faEnvelope, faEnvelopeSolid, faFileDownload, faArrowRight, faArrowLeft);
dom.watch();

window.jQuery = window.$ = $;
require('lightgallery.js');
// require('lg-video.js');
// require('lg-thumbnail.js');

import './lib/foundation-explicit-pieces';
$(document).foundation();

function readyFn() {

    $('iframe[src*="youtube"]').each(function () {
        if (!$(this).parent().hasClass("flex-video")) {
            $(this).wrap("<div class='responsive-embed widescreen'/>");
        }
    });


    $('body').on('click', '.newWindow, .newWindowNoIcon, a.pdflink, a.xlslink', function(){
        window.open($(this).attr('href'));
        return false;
    });

    if ($('.person-email-click').length) {
        var $contactSelect = $('#recipient');
        $('.person-email-click').on('click', function (e) {
            var recipient = $(this).data('recipient');
            $contactSelect.val(recipient);
            $("#contactform").velocity("scroll", { duration: 500, offset: -100 });
        });
    }

    
    function debounce(func, wait, immediate) {
        var timeout;
        return function() {
            var context = this, args = arguments;
            var later = function() {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    };
    
    function addLoadMoreButton() {
        let numDisplayedNewsItems = $('.news-card').length;
        let maxNewsItems = $('#total-news-items').val();
        let $loadMoreHolder = $('#load-more-holder');
        let $button = $('<button class="load-more small button" data-current-page="1" data-parent="14">Load more</button>');
    
        if ( numDisplayedNewsItems < maxNewsItems ) {
            $loadMoreHolder.append($button);
        }
    }
    
    $('#news').on('click', '.load-more', function(e) {
        e.preventDefault();
    
        var $this = $(this);
    
        var load   = $this.data('current-page') + 1;
        var parent = $this.data('parent');
    
        $.ajax({
            type: "GET",
            url: "/api/paginate",
            data: {page: load, parents: parent},
            success: function (response) {
                response = $.parseJSON(response);
    
                if(response.success) {
                    $(".news-summary--list").append(response.page);
    
                    // Update the page attribute
                    $this.data('current-page', load);
                    Foundation.reInit('equalizer');
                } else {
                    $this.after(response.page);
                    $this.remove();
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                // Do something if there was an error
            }
        });
    });    

    gdprCookieNotice({
        locale: 'en', //This is the default value
        timeout: 500, //Time until the cookie bar appears
        expiration: 30, //This is the default value, in days
        domain: '', //If you run the same cookie notice on all subdomains, define the main domain starting with a .
        implicit: true, //Accept cookies on page scroll automatically
        statement: '/privacy-policy/', //Link to your cookie statement page
        performance: ['PHPSESSID'], //Cookies in the performance category.
        analytics: ['_ga','_ga_E55MVKR7KG'], //Cookies in the analytics category.
        marketing: ['SSID'] //Cookies in the marketing category.
    });

    addLoadMoreButton();

    lightGallery(document.getElementById('lg-gallery'), {
        selector: '.thumb',
        download: false
    });

    Sliders.init();

    let LazyLoadHandler = new LazyLoader('.js-image');
    LazyLoadHandler.ObserveAll();

    
}

// kick things off


window.addEventListener('load', retina);
window.addEventListener('load', readyFn);
